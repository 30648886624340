body {
    --kiamo-bottom-position: 10px;
    --kiamo-opacity: 1;

    #kiamo-chat {
        transition: all .5s;
        height: fit-content !important;
        bottom: var(--kiamo-bottom-position) !important;
        opacity: var(--kiamo-opacity) !important;
        font-size: 16px;
        font-family: Roboto !important;

        &:not(.kc-small) {
            #kc-header {
                #kc-topbar-actions {
                    .kc-icon {
                        &.kc-lower, &.kc-upper {
                            display: inline-block;
                        }
                    }
                }
            }

            #kc-body {
                padding-bottom: 0.625rem;
                font-size: .688em;
            }
        }

        &.kc-small {
            width: fit-content;

            #kc-header {
                height: 42px;
                background: none;
                border: none;

                #kc-logo {
                    background-image: url('/Images/kiamo-chat/icon-tchat.svg');
                    width: 50px;
                    height: 42px;
                    margin: 0;
                }

                #kc-topbar-actions {
                    .kc-toggler.maximize {
                        display: none;
                    }
                }
            }

            #kc-body {
                padding: 0;
            }
        }

        &.kc-conversation {
            #kc-body {
                padding-bottom: 0;
            }
        }

        &.kc-hidden {
            display: none;
        }

        &.kc-invite {
            #kc-body {
                p {
                    font-size: inherit;
                }

                button {
                    // Bidouille pour avoir du 16px par défaut
                    // On ne peut pas attribuer de valeur fixe car quand clique sur + ou - ça n'a pas d'effet
                    // Donc on utilise une valeur en % pour hériter de la font-size du kc-body
                    font-size: calc(100% * 1.45);
                }
            }
        }

        #kc-header {
            height: 40px;

            #kc-logo {
                width: 34px;
                height: 26px;
                background-image: url('/Images/kiamo-chat/icon-tchat.svg');
                background-position: center;
                background-size: contain;
                margin-top: 7px;
            }

            #kc-topbar-actions {
                margin-top: 7px;

                .kc-icon {
                    &.kc-lower {
                        background-image: url('/Images/kiamo-chat/icon-font-moins.svg');
                        background-position: left center;
                        position: relative;
                        margin-right: 28px;

                        &:after {
                            content: url('/Images/kiamo-chat/icon-font-size.svg');
                            position: absolute;
                            top: 50%;
                            right: -28px;
                            transform: translateY(-50%);
                            pointer-events: none;
                        }
                    }

                    &.kc-upper {
                        background-image: url('/Images/kiamo-chat/icon-font-plus.svg');
                        background-position: center;
                        position: relative;
                        margin-right: 3px;

                        &:after {
                            content: url('/Images/kiamo-chat/separator.svg');
                            position: absolute;
                            top: 50%;
                            right: -3px;
                            transform: translateY(-50%);
                            pointer-events: none;
                        }
                    }

                    &.kc-toggler.minimize {
                        background-image: url('/Images/kiamo-chat/icon-close-tchat.svg');
                        background-position: center;
                        display: inline-block !important;
                    }

                    &.close {
                        display: none;
                    }
                }
            }
        }

        #kc-body {
            & > div {
                display: flex;
                gap: 1rem;
                justify-content: center;

                &[data-stid] {
                    flex-wrap: wrap;
                }

                button {
                    line-height: 1 !important;
                    height: 2.8125rem;
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-left: .625rem !important;
                    padding-right: .625rem !important;
                    transition: background .5s !important;
                    margin: 0 !important;
                    display: flex !important;
                    justify-content: center;
                    flex-wrap: wrap;
                    align-items: center;
                    width: fit-content;
                    background: white;
                    color: #333;
                    border: solid 1px #333;
                    border-radius: 0;
                    font-size: 1rem;
                    font-weight: bold;
                    font-family: Roboto;

                    &:hover {
                        background: #333;
                        color: white;
                    }
                }

                p {
                    width: fit-content;
                    padding: inherit !important;
                }

                &#kc-message-list {
                    background-image: url('/Images/kiamo-chat/background-tchat.png');
                    flex-direction: column;
                    justify-content: inherit;
                    row-gap: 0;

                    .kc-message {
                        .kc-message-container {
                            background: #CCCCCC4d;
                            width: fit-content;
                            padding: .5rem;
                            border-radius: .5rem;
                            box-shadow: none;

                            .kc-message-content {
                                width: fit-content;
                                color: #333;
                                font-family: "Roboto";
                                line-height: 1.2;

                                .networks-contact {
                                    display: flex;
                                    justify-content: center;
                                    gap: 0.625rem;
                                    align-items: center;

                                    .logo {
                                        &.mail {
                                            background-image: url('/Images/kiamo-chat/icon-mail.svg');
                                            height: 32px;
                                            width: 39px;
                                            display: inline-block;
                                        }

                                        &.whatsapp {
                                            background-image: url('/Images/kiamo-chat/icon-whatsapp.svg');
                                            height: 40px;
                                            width: 40px;
                                            display: inline-block;
                                        }

                                        &.messenger {
                                            background-image: url('/Images/kiamo-chat/icon-messenger.svg');
                                            width: 40px;
                                            height: 40px;
                                            display: inline-block;
                                        }
                                    }
                                }
                            }
                        }

                        &:not(.client) {
                            .kc-message-container {
                                border-top-left-radius: 0;

                                .kc-message-content {
                                    text-align: left;
                                }
                            }
                        }

                        &.client {
                            .kc-message-container {
                                background: #6666664d;
                                border-top-right-radius: 0;

                                .kc-message-content {
                                    text-align: right;
                                }
                            }
                        }

                        &.script, &.auto {
                            .kc-message-container {
                                background: transparent;
                                max-width: inherit;
                                padding-bottom: 0;
                                width: 100%;

                                .kc-message-content {
                                    width: 100%;
                                    text-align: center;
                                }
                            }

                            & + .kc-message.button {
                                margin-top: 0 !important;

                                .kc-message-container {
                                    padding: 0 !important;
                                }
                            }
                        }

                        &.button {
                            height: fit-content;
                            padding: 0 !important;
                            margin: 5px !important;
                            width: calc(100% - 10px);

                            .kc-message-container {
                                width: 100%;
                                background: transparent;

                                .kc-message-content {
                                    margin: auto;

                                    .kc-row {
                                        display: flex;

                                        .kc-col-6 {
                                            // Etoiles du questionnaire de satisfaction
                                            .kc-btn {
                                                all: inherit;
                                                border: 0;
                                                background: transparent;
                                                padding: 0px !important;
                                                height: 30px;
                                                width: 40px;
                                                margin: 0;
                                                content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30.053" viewBox="0 0 30 30.053"><g transform="translate(-241 -565)"><path id="Polygone_1" data-name="Polygone 1" d="M14.087,2.043a1,1,0,0,1,1.826,0l3.359,7.518a1,1,0,0,0,.782.583l7.919,1.047a1,1,0,0,1,.571,1.7L22.657,18.7a1,1,0,0,0-.282.891l1.507,8.281A1,1,0,0,1,22.4,28.916l-6.9-3.993a1,1,0,0,0-1,0L7.6,28.916a1,1,0,0,1-1.485-1.045l1.507-8.281a1,1,0,0,0-.282-.891L1.456,12.894a1,1,0,0,1,.571-1.7l7.919-1.047a1,1,0,0,0,.782-.583Z" transform="translate(241 565)" fill="%23fff"/><path id="Polygone_1_-_Contour" data-name="Polygone 1 - Contour" d="M15,.451a1.977,1.977,0,0,1,1.826,1.184l3.359,7.518L28.1,10.2a2,2,0,0,1,1.142,3.407L23.36,19.412l1.507,8.281a2,2,0,0,1-2.97,2.089L15,25.789,8.1,29.782a2,2,0,0,1-2.97-2.089L6.64,19.412.753,13.606A2,2,0,0,1,1.9,10.2L9.815,9.153l3.359-7.518A1.977,1.977,0,0,1,15,.451ZM27.842,12.182l-7.919-1.047a2.013,2.013,0,0,1-1.564-1.167L15,2.451,11.641,9.969a2.013,2.013,0,0,1-1.564,1.167L2.158,12.182h0l5.887,5.806a2,2,0,0,1,.563,1.782L7.1,28.051,14,24.058a2,2,0,0,1,2,0l6.9,3.993L21.392,19.77a2,2,0,0,1,.563-1.782l5.887-5.806h0Z" transform="translate(241 565)" fill="%23ccc"/></g></svg>');

                                                &.-hover {
                                                    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30.053" viewBox="0 0 30 30.053"><g transform="translate(-241 -565)"><path id="Polygone_1" data-name="Polygone 1" d="M14.087,2.043a1,1,0,0,1,1.826,0l3.359,7.518a1,1,0,0,0,.782.583l7.919,1.047a1,1,0,0,1,.571,1.7L22.657,18.7a1,1,0,0,0-.282.891l1.507,8.281A1,1,0,0,1,22.4,28.916l-6.9-3.993a1,1,0,0,0-1,0L7.6,28.916a1,1,0,0,1-1.485-1.045l1.507-8.281a1,1,0,0,0-.282-.891L1.456,12.894a1,1,0,0,1,.571-1.7l7.919-1.047a1,1,0,0,0,.782-.583Z" transform="translate(241 565)" fill="%23fff"/><path id="Polygone_1_-_Contour" data-name="Polygone 1 - Contour" d="M15,.451a1.977,1.977,0,0,1,1.826,1.184l3.359,7.518L28.1,10.2a2,2,0,0,1,1.142,3.407L23.36,19.412l1.507,8.281a2,2,0,0,1-2.97,2.089L15,25.789,8.1,29.782a2,2,0,0,1-2.97-2.089L6.64,19.412.753,13.606A2,2,0,0,1,1.9,10.2L9.815,9.153l3.359-7.518A1.977,1.977,0,0,1,15,.451ZM27.842,12.182l-7.919-1.047a2.013,2.013,0,0,1-1.564-1.167L15,2.451,11.641,9.969a2.013,2.013,0,0,1-1.564,1.167L2.158,12.182h0l5.887,5.806a2,2,0,0,1,.563,1.782L7.1,28.051,14,24.058a2,2,0,0,1,2,0l6.9,3.993L21.392,19.77a2,2,0,0,1,.563-1.782l5.887-5.806h0Z" transform="translate(241 565)" fill="%23f8b92c"/></g></svg>');
                                                }

                                                &.kc-selected {
                                                    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30.053" viewBox="0 0 30 30.053"><g transform="translate(-241 -565)"><path id="Polygone_1" data-name="Polygone 1" d="M14.087,2.043a1,1,0,0,1,1.826,0l3.359,7.518a1,1,0,0,0,.782.583l7.919,1.047a1,1,0,0,1,.571,1.7L22.657,18.7a1,1,0,0,0-.282.891l1.507,8.281A1,1,0,0,1,22.4,28.916l-6.9-3.993a1,1,0,0,0-1,0L7.6,28.916a1,1,0,0,1-1.485-1.045l1.507-8.281a1,1,0,0,0-.282-.891L1.456,12.894a1,1,0,0,1,.571-1.7l7.919-1.047a1,1,0,0,0,.782-.583Z" transform="translate(241 565)" fill="%23fff"/><path id="Polygone_1_-_Contour" data-name="Polygone 1 - Contour" d="M15,.451a1.977,1.977,0,0,1,1.826,1.184l3.359,7.518L28.1,10.2a2,2,0,0,1,1.142,3.407L23.36,19.412l1.507,8.281a2,2,0,0,1-2.97,2.089L15,25.789,8.1,29.782a2,2,0,0,1-2.97-2.089L6.64,19.412.753,13.606A2,2,0,0,1,1.9,10.2L9.815,9.153l3.359-7.518A1.977,1.977,0,0,1,15,.451ZM27.842,12.182l-7.919-1.047a2.013,2.013,0,0,1-1.564-1.167L15,2.451,11.641,9.969a2.013,2.013,0,0,1-1.564,1.167L2.158,12.182h0l5.887,5.806a2,2,0,0,1,.563,1.782L7.1,28.051,14,24.058a2,2,0,0,1,2,0l6.9,3.993L21.392,19.77a2,2,0,0,1,.563-1.782l5.887-5.806h0Z" transform="translate(241 565)" fill="%23f8b92c"/></g></svg>');
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            & + .kc-message.script {
                                margin-top: 0 .5rem !important;

                                .kc-message-container {
                                    padding-top: 0 !important;
                                }
                            }
                        }
                    }
                }
            }

            #kc-message-form {
                border-top: 1px solid #e1e1e1;
                box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);

                &.-disabled {
                    height: 0;
                }

                #kc-message {
                    color: #333;
                    max-width: 240px;
                    box-shadow: none;

                    &[disabled] {
                        max-width: inherit;

                        & + #kc-submit-message {
                            display: none !important;
                        }
                    }
                }

                #kc-submit-message {
                    display: block;
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 0;
                    background-color: transparent;
                    background-image: url('/Images/kiamo-chat/button-send.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    padding: 0 !important;
                    height: 30px;
                    width: 30px;
                    border: none;
                }
            }
        }
    }
}
